import { createStyles, Theme } from '@material-ui/core/styles'
import { deepOrange, brown } from '@material-ui/core/colors'

import palette from '~/ThemeProvider/config/palette'
import { Props } from './'

export const Styled = (theme: Theme) =>
createStyles({
  root: {
    position: 'absolute',
    left: 10,
    top: 10,
    color: palette.common.white,
    zIndex: 8,
    width: '100%'
  },
  rootRelative: {
    position: 'relative',
    color: palette.common.white,
    width: '100%'
  },
  chip: {
    color: palette.common.white,
    height: (props: Props) => props.size === 'xsmall' ? 14 : 'auto',
    '&label': {
      display: 'flex'
    }
  },
  chipBrown: {
    color: palette.common.white,
    backgroundColor: brown[500],
    height: (props: Props) => props.size === 'xsmall' ? 14 : 'auto',
    '&label': {
      display: 'flex'
    }
  },
  chipLabel: {
    display: 'flex'
  },
  text: {
    lineHeight: 1
  },
  pink: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: palette.secondary.dark
  }
})
