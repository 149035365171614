import React from 'react'
import { useTranslation } from 'react-i18next'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FavoriteIcon from '@material-ui/icons/Favorite'

import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import useFavoriteLead from '~/hooks/useFavoriteLead'
import { SearchLead } from '~/types/Search'

export interface Props {
  item: SearchLead | null
  leadId?: string
  secondary?: boolean
}

const BuyerFavButton: React.FC<Props> = ({
  item, leadId, secondary
}) => {
  const { t } = useTranslation()
  const [toggleFavorite, isFav] = useFavoriteLead(item, leadId)

  const handleFavoriteClick = (e: React.MouseEvent) => {
    if (!toggleFavorite) {
      return
    }

    e.stopPropagation()

    toggleFavorite()
  }

  return (
    <Button onClick={handleFavoriteClick} size='small' color={secondary ? 'secondary' : 'primary'} startIcon={isFav ? <FavoriteIcon /> : <FavoriteBorderIcon />} variant='text'>
      <Typography size='small' color={secondary ? 'textPrimary' : 'inherit'}>
        {t('label-liked')}
      </Typography>
    </Button>
  )
}

export default BuyerFavButton
