import { useTranslation } from 'react-i18next'

import segment from '~/services/segment'
import { MessageProps, formatILStreetAddress, getPropertyUrl, getPublicPropertyUrl, sendWhatsApp } from '~/components/utils/contacts'
import useContactByEmailModal from '~/hooks/useContactByEmailModal'

type EmailProps = MessageProps & {email: string, skipCopy?: boolean}
type PhoneProps = MessageProps & {phoneNumber: string}

interface HookReturn {
  sellerToBuyerEmail: (data: EmailProps) => void,
  sellerToBuyerWA: (data: PhoneProps) => void,
  buyerToSellerEmail: (data: EmailProps) => void,
  buyerToSellerWA: (data: PhoneProps) => void
}

export default function useContactMessage(isPublic?: boolean): HookReturn {
  const [renderEmailModal] = useContactByEmailModal()
  const { t } = useTranslation()

  const sellerToBuyerTextBody = ({ address, city, leadId }: MessageProps) => {
    const text = `${t('contactMsg-start')}.
    ${t('contactMsg-seller-part1')} ${formatILStreetAddress(address, true) || ''}${city ? ` ${t('contactMsg-in')}${city}` : ''}, ${t('contactMsg-seller-relevant')}\n ${t('ToWatchClickHere')}:
    ${getPropertyUrl(leadId)}
    `

    return encodeURIComponent(text)
  }

  const buyerToSellerTextBody = ({ address, city, leadId }: MessageProps) => {
    const text = `${t('contactMsg-start')}.
    ${t('contactMsg-buyer-part1')} ${formatILStreetAddress(address, true) || ''} ${city || ''}, ${t('contactMsg-buyer-relevant')}\n ${t('ToWatchClickHere')}:
    ${getPropertyUrl(leadId)}
    `

    return encodeURIComponent(text)
  }

  const publicToSellerTextBody = ({ address, city, leadId }: MessageProps) => {
    const text = `${t('contactMsg-start-public')}.
    ${t('contactMsg-in-street')} ${formatILStreetAddress(address, true) || ''} ${city || ''}, ${t('contactMsg-buyer-relevant')}\n ${t('ToWatchClickHere-public')}:
    ${getPublicPropertyUrl(leadId)}
    `

    return encodeURIComponent(text)
  }

  const handleSellerToBuyerEmail = ({ address, city, leadId, email }: EmailProps): void => {
    const text = sellerToBuyerTextBody({ address, city, leadId })

    renderEmailModal(email, text, {
      trackName: 'Seller Communicate Buyer - Email',
      payload: {
        leadId
      }
    })
  }

  const handleSellerToBuyerWA = ({ address, city, leadId, phoneNumber }: PhoneProps): void => {
    const text = sellerToBuyerTextBody({ address, city, leadId })

    segment.track('Seller Communicate Buyer - WA', {
      leadId
    })
    sendWhatsApp(phoneNumber, text)
  }

  const handleBuyerToSellerEmail = ({ address, city, leadId, email }: EmailProps): void => {
    const text = isPublic ? publicToSellerTextBody({ address, city, leadId }) : buyerToSellerTextBody({ address, city, leadId })

    renderEmailModal(email, text, {
      trackName: 'buyer Communicate Seller - Email',
      payload: {
        leadId,
        isPublic: !!isPublic
      }
    })
  }

  const handleBuyerToSellerWA = ({ address, city, leadId, phoneNumber }: PhoneProps): void => {
    const text = isPublic ? publicToSellerTextBody({ address, city, leadId }) : buyerToSellerTextBody({ address, city, leadId })

    sendWhatsApp(phoneNumber, text)
    segment.track('buyer Communicate Seller - WA', {
      leadId,
      isPublic: !!isPublic
    })
  }

  return {
    sellerToBuyerEmail: handleSellerToBuyerEmail,
    sellerToBuyerWA: handleSellerToBuyerWA,
    buyerToSellerEmail: handleBuyerToSellerEmail,
    buyerToSellerWA: handleBuyerToSellerWA
  }
}
