import React from 'react'
import OriginImageGallery from 'react-image-gallery'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import './styles'
import { getCdnEndpoint } from '~/components/utils/contacts';

export interface Props {
  images: string[]
  isShowBullets?: boolean
  isShowNav?: boolean
  allowFullScreen?: boolean
  showThumbnails?: boolean
  customClass?: string
}

// eslint-disable-next-line complexity
const ImageGallery: React.FC<Props> = ({
  images, isShowBullets, isShowNav, allowFullScreen, showThumbnails = false, customClass
}) => {
  const [isFullScreen, setIsFullScreen] = React.useState(false)
  const _imageGallery = React.useRef<OriginImageGallery>(null)

  React.useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        setIsFullScreen(false)
      }
    }

    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  const imgsArrFix = images.map((img) => {
    const fixHttpsStr = (img.includes('https')) ? img : `https${img.split('http')[1]}`

    return ({
      original: fixHttpsStr,
      thumbnail: fixHttpsStr
    })
  })
  const renderLeftNav: React.FC<any> = (onClick) => {
    return (
      <ArrowBackIosRoundedIcon
        fontSize='large'
        htmlColor='white'
        className='image-gallery-custom-left-nav'
        onClick={(e) => {
          e.stopPropagation()

          return onClick(e.stopPropagation())
        }}
      />
    )
  }
  const renderRightNav: React.FC<any> = (onClick) => {
    return (
      <ArrowForwardIosRoundedIcon
        fontSize='large'
        htmlColor='white'
        className='image-gallery-custom-right-nav'
        onClick={(e) => {
          e.stopPropagation()

          return onClick(e.stopPropagation())
        }}
      />
    )
  }

  const hanleCloseGallery = () => {
    _imageGallery?.current?.exitFullScreen()
    setIsFullScreen(false)
  }

  const handleGalleryClick = () => {
    if (!isFullScreen) {
      _imageGallery?.current?.fullScreen()
      setIsFullScreen(true)
    }
  }

  if (!images.length) {
    return (
      <img src={getCdnEndpoint('no_image.png')} alt='no_image' style={{ width: '100%', height: '200px', borderRadius: '12px' }} />
    )
  }

  return (
    <div>
      {isFullScreen
        ? <IconButton onClick={hanleCloseGallery}>
          <HighlightOffIcon
            fontSize='large'
            htmlColor='white'
            style={{
              position: 'fixed', top: '0', right: '5px', zIndex: 8001
            }}
          />
          </IconButton>
         : null}
      <OriginImageGallery
        // className='image-gallery'
        ref={_imageGallery}
        showThumbnails={showThumbnails}
        additionalClass={customClass}
        thumbnailPosition={showThumbnails ? 'right' : undefined}
        showBullets={isShowBullets}
        showPlayButton={false}
        showNav={isShowNav}
        showFullscreenButton={false}
        useBrowserFullscreen={false}
        onClick={allowFullScreen ? handleGalleryClick : undefined}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
        items={imgsArrFix}
      />
    </div>
  )
}

export default ImageGallery
