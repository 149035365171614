import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { MenuList, ClickAwayListener, Grow, Popper } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Button from '~/components/core/Button'
import * as S from './styles'

const useStyles = makeStyles(S.AssetCardStyled)

export interface Props {
  label: React.ReactNode
  children: React.ReactNode
}

const DropdownLink: React.FC<Props> = ({
  children, label
}) => {
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef?.current && anchorRef?.current?.contains(event.target as HTMLElement)) {
      return
    }

    event.stopPropagation()
    setOpen(false);
  }

  return (
    <div ref={anchorRef} className={classes.botton}>
      <Button
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        endIcon={<ExpandMoreIcon />}
        variant='text'
        onClick={handleToggle}
      >
        {label}
      </Button>
      <Popper className={classes.root} open={open} anchorEl={anchorRef?.current} role={undefined} disablePortal placement='bottom'>
        {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', zIndex: 10 }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    id='menu-list-grow' onClick={() => setOpen(false)} className={classes.list}
                  >
                  {children}
                  </MenuList>
                </ClickAwayListener>
              </Grow>
        )}
      </Popper>
    </div>

  )
}

export default DropdownLink
