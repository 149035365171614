import React, { FC, HTMLAttributes, ReactChild } from 'react'

import HoomeTheme from './ThemeProvider'
// import Hoome from '~/components/modules/Hoome'
import ConstructionPage from '~/components/pages/ConstructionPage'
import { initialize } from '~/i18n'

initialize()

export interface Props extends HTMLAttributes<HTMLDivElement> {
  /** custom content, defaults to 'the snozzberries taste like snozzberries' */
  children?: ReactChild;
}

const App: FC<Props> = () => {
  return (
    <HoomeTheme>
      <ConstructionPage />
    </HoomeTheme>
  )
}

export default App
