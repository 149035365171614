import React, { useState } from 'react'
import { useModal } from 'react-modal-hook'

import EmailContactModal, { TrackEvent } from '~/components/modules/EmailContactModal'

export default function useContactByEmailModal(): [(email: string, text?: string, trackEvent?: TrackEvent) => void] {
  const [emailAddress, setEmailAddress] = useState<{email?: string, text?: string, trackEvent?: TrackEvent}>({})
  const [showModal, hideModal] = useModal(() => (
    <EmailContactModal
      onClose={hideModal}
      isOpen
      email={emailAddress.email}
      text={emailAddress.text}
      trackEvent={emailAddress.trackEvent}
    />
  ), [emailAddress.email])

  const renderEmailModal = (email: string, text?: string, trackEvent?: TrackEvent) => {
    setEmailAddress({ email, text, trackEvent })

    showModal()
  }

  return [renderEmailModal]
}
