import React from 'react'
import { Grid, Container } from '@material-ui/core'
import BuildIcon from '@material-ui/icons/Build'

import ResultsMessage from '~/components/core/ResultsMessage'
import Page from '~/components/core/CompletePage'

interface Props {
  modal?: boolean
}

const ConstructionPage: React.FC<Props> = () => {
  return (
    <Page withSidebar={false} isPublic>
      <Container>
        <Grid container direction='column' alignItems='center' spacing={2} style={{ marginTop: 80 }}>
          <Grid item>
            <ResultsMessage
              title='עבודות תשתית'
              subTitle='האתר בעבודות תשתית עמכם הסליחה נחזור בהקדם'
              icon={<BuildIcon color='action' fontSize='large' style={{ height: 150, width: 'auto', marginTop: 40 }} />}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default ConstructionPage
