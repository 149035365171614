export interface MessageProps {
    address: string
    city: string
    leadId: string
}

export const formatPhoneNumberForWhatsapp = (num: string): string => {
    return (`972${num.substr(1)}`)
}

export const formatILStreetAddress = (str: string, onlyStreet = false): string => {
    if (onlyStreet) {
        return str.split(',')[0]
    }
    if (str.includes(', ישראל')) {
        return str.replace(', ישראל', '')
    }

    return str
}

export const getSellerToBuyerMessage = ({ address, city, leadId }: MessageProps): string => {
    const isRlevantTextSeller = 'contact-buyer-relevant'
    const start = 'contactMsg-start'
    const text = `${start}.
  contactMsg-buyer-part1 ${formatILStreetAddress(address, true) || ''} ב${city || ''}, ${isRlevantTextSeller}
  ${getPropertyUrl(leadId)}
  `

    return encodeURIComponent(text)
}

export const getBuyerToSellerMessage = ({ address, city, leadId }: MessageProps): string => {
    const isRlevantTextBuyer = 'contact-seller-relevant'
    const start = 'contactMsg-start'
    const text = `${start}.
  contactMsg-seller-part1 ${formatILStreetAddress(address, true) || ''} contactMsg-in${city || ''}, ${isRlevantTextBuyer}
  ${getPropertyUrl(leadId)}
  `

    return encodeURIComponent(text)
}

export const getPropertyUrl = (leadId: string): string => {
    return (
      `${window.location.origin}/match/${leadId}`
    )
}

export const getPublicPropertyUrl = (assetId: string): string => {
    return (
      `${window.location.origin}/public/asset/${assetId}`
    )
}

export const sendEmail = (email: string, msg: string): void => {
    window.open(`mailto:${email}?subject=פנייה אישית מהאתר הומי&&body=${msg}`)
}

export const sendWhatsApp = (phoneNumber: string, msg: string): void => {
    window.open(`https://wa.me/${formatPhoneNumberForWhatsapp(phoneNumber)}?text=${msg}`)
}

const cdnEndpoint = 'https://storage.googleapis.com/hoome-imgs/app-assets/images';

export const getCdnEndpoint = (fileName: string): string => `${cdnEndpoint}/${fileName}`
