import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AddIcon from '@material-ui/icons/Add'
import FavoriteIcon from '@material-ui/icons/Favorite'

import Sidebar from '~/components/core/Sidebar'
import Typography from '~/components/core/Typography'
import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import { addressWithoutCountry } from '~/components/utils/formating'

const ViewerSidebarSearchesItem: React.FC = () => {
  const history = useHistory()
  const { searches, newSearchLeads } = React.useContext(ViewerProvider)
  const { t } = useTranslation()

  if (!searches) {
    return null
  }

  return (
    <>
      <Sidebar.NestedItem text={t('home-buyer')} icon={<SearchIcon />}>
        <Link key='favoritesSearchesSidebar' to='/buyer/favorites' style={{ textDecoration: 'none', width: '100%' }}>
          <Sidebar.Item icon={<FavoriteIcon color='secondary' />} text={<Typography size='small' color='textPrimary'>{t('myFavoritesAssets')}</Typography>} />
        </Link>
        <Link key='postSearchSidebar' to='/buyer/postSearch' style={{ textDecoration: 'none', width: '100%' }}>
          <Sidebar.Item icon={<AddIcon />} text={<Typography size='small' color='textPrimary'>{t('newSearchAdded')}</Typography>} />
        </Link>
        {
          (searches || []).map((search) => {
            const searchNewLeads = newSearchLeads?.find(({ id: searchId }) => searchId === search.id)

            return (
              <Link color='inherit' key={search.id} to={`/buyer/search/${search.id}`} style={{ textDecoration: 'none', width: '100%' }}>
                <Sidebar.Item
                  selected={history.location.pathname.includes(search.id)}
                  text={<Typography title={search.city} size='small' color='textPrimary'>{addressWithoutCountry(search.city)}</Typography>}
                  secondaryText={<Typography size='small' color='textPrimary'>{search.forSale ? t('labelForSale') : t('labelForRent')}</Typography>}
                  badge={searchNewLeads?.numberOfNewLeads || undefined}
                />
              </Link>
            )
          })
        }
      </Sidebar.NestedItem>
    </>
  )
}

export default ViewerSidebarSearchesItem
