import React from 'react'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AddIcon from '@material-ui/icons/Add'

import Sidebar from '~/components/core/Sidebar'
import Typography from '~/components/core/Typography'
import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import { addressWithoutCountry } from '~/components/utils/formating'

const ViewerSidebarAssetsItem: React.FC = () => {
  const history = useHistory()
  const { assets, newAssetLeads } = React.useContext(ViewerProvider)
  const { t } = useTranslation()

  if (!assets) {
    return null
  }

  return (
    <>
      <Sidebar.NestedItem text={t('home-seller')} icon={<HomeWorkIcon />}>
        <Link key='postAssetNavBar' to='/seller/postAsset' style={{ textDecoration: 'none', width: '100%' }}>
          <Sidebar.Item icon={<AddIcon />} text={<Typography size='small' color='textPrimary'>{t('addPropery')}</Typography>} />
        </Link>
        {
          (assets || []).map((asset) => {
            const assetNewLeads = newAssetLeads?.find(({ id }) => id === asset.id)

            return (
              <Link key={asset.id} to={`/seller/asset/${asset.id}/leads`} style={{ textDecoration: 'none', width: '100%' }}>
                <Sidebar.Item
                  selected={history.location.pathname.includes(asset.id)}
                  text={<Typography title={asset.address} size='small' color='textPrimary'>{addressWithoutCountry(asset.address)}</Typography>}
                  secondaryText={<Typography size='small' color='textPrimary'>{asset.forSale ? t('labelForSale') : t('labelForRent')}</Typography>}
                  badge={assetNewLeads?.numberOfNewLeads || undefined}
                />
              </Link>
            )
          })
        }
      </Sidebar.NestedItem>
    </>
  )
}

export default ViewerSidebarAssetsItem
