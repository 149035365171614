import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

import { useBreakpoint } from '~/utils/breakpoints'
import ViewerSidebar from '~/components/modules/ViewerSidebar'
import Page from '~/components/core/Page'
import { PageHeader } from '~/components/core/Page/PageHeader'
import Content from '~/components/core/Page/PageContent'
import Footer from '~/components/core/Page/PageFooter'
import MapView from '~/components/core/MapView'
import MapProvider from '~/components/core/MapProvider'
import ViewerNavbar from '~/components/modules/ViewerNavbar'

interface Props {
  hideOnScroll?: boolean
  withSidebar?: boolean
  varient?: 'dark' | 'light'
  children: React.ReactNode
  isPublic?: boolean
}

// eslint-disable-next-line complexity
const Complete: React.FC<Props> = ({ withSidebar = true, varient = 'light', children, isPublic }) => {
  const [open, setOpen] = React.useState(false)
  const [isMapShow, setIsMapShow] = React.useState(false)
  const isCompactLayout = useBreakpoint(['small', 'medium'])

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Page width={isMapShow ? 'full' : 'wide'}>
      <PageHeader
        varient={varient}
        logoAlign={isCompactLayout ? 'center' : 'end'}
        leftContent={withSidebar && (
          <>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
          >
            <MenuIcon />
          </IconButton>
          {!isCompactLayout && <ViewerNavbar />}
          </>
        )}
        position='absolute'
        // hideOnScroll={!map}
      />
      <Content>
        <MapProvider onMapChange={setIsMapShow}>
          <Grid container>
            <Grid item container style={{ flex: 1 }}>
              <Container disableGutters={!isCompactLayout} style={isCompactLayout ? { padding: '0 8px' } : {}}>
                <>{children}</>
              </Container>
            </Grid>
            {(!isCompactLayout) && <MapView sticky />}
          </Grid>
        </MapProvider>
      </Content>
      {(!isPublic && withSidebar) && (
        <ViewerSidebar onClose={handleDrawerClose} open={open} />
      )}
      {!isCompactLayout && <Footer />}
    </Page>
  )
}

export default Complete
