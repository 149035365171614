import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Chip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Typography from '~/components/core/Typography'
import formattedDeviation from '~/components/utils/formattedDeviation'
import * as S from './styles'

const useStyles = makeStyles(S.Styled)

export interface Props {
  isNew?: boolean
  viewed?: boolean
  deviation?: number
  relative?: boolean
  size?: 'medium' | 'small' | 'xsmall'
}

// eslint-disable-next-line complexity
export const SearchLeadLabels: React.FC<Props> = (props) => {
  const {
    isNew,
    deviation,
    viewed,
    relative,
    size = 'xsmall'
  } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const chipSize: ('medium' | 'small') = size === 'xsmall' ? 'small' : size

  return (
    <div className={relative ? classes.rootRelative : classes.root}>
      <Grid container spacing={2} wrap='nowrap'>
        {
          (isNew) && (
            <Grid item>
              <Chip classes={{ label: classes.chipLabel }} size={chipSize} label={<Typography classes={{ root: classes.text }} size={size} color='inherit'>{t('new')}</Typography>} className={classes.chip} color='primary' />
            </Grid>
          )
        }
        {
          !!(deviation) && (
            <Grid item>
              <Chip classes={{ label: classes.chipLabel }} size={chipSize} label={<Typography classes={{ root: classes.text }} size={size} color='inherit'>{`${formattedDeviation(deviation)}% ${t('priceDeviation')}`}</Typography>} className={classes.chip} color='secondary' />
            </Grid>
          )
        }
        {
          !!(viewed) && (
            <Grid item>
              <Chip classes={{ label: classes.chipLabel }} size={chipSize} label={<Typography classes={{ root: classes.text }} size={size} color='inherit'>{t('leadViewedAsset')}</Typography>} className={classes.chipBrown} />
            </Grid>
          )
        }
      </Grid>
    </div>
  )
}

export default memo(SearchLeadLabels)
