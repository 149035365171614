import { createStyles } from '@material-ui/core/styles'

import palette from '~/ThemeProvider/config/palette'

export const AssetCardStyled = createStyles({
  root: {
    width: 'auto',
    backgroundColor: palette.common.white,
    zIndex: 10,
    boxShadow: `0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)`,
    padding: 20,
    marginTop: 10
  },
  botton: {
    position: 'relative'
  },
  list: {
    zIndex: 10
  }
})
